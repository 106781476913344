import React from "react";
import Button from "../../_shared/componenets/Button/Button";
import * as styles from "./CookiesBanner.module.css";
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown";
import { useState } from "react";
import CookiesType from "./CookiesType/CookiesType";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import Cookies from "js-cookie";
import { useEffect } from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";

const CookiesBanner = () => {
  const { t } = useTranslation();

  const [displayBanner, setDisplayBanner] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [analyticsCookiesActive, setAnalyticsCookiesActive] = useState(true);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const location = useLocation();

  useEffect(() => {
    setDisplayBanner(Cookies.get("oxynapse_consent") !== "true");
  }, []);

  const handleAccept = () => {
    Cookies.set("oxynapse-consent-analytics", true, { expires: 365 });
    handleCloseAll();
  };

  const handleDecline = () => {
    Cookies.remove("oxynapse-consent-analytics");
    handleCloseAll();
  };

  const handleSavePreferences = () => {
    if (setAnalyticsCookiesActive) handleAccept();
    else handleDecline();
  };

  const handleCloseAll = () => {
    setDisplayBanner(false);

    Cookies.set("oxynapse_consent", true, { expires: 365 });
    Cookies.set("oxynapse-consent-neccessary", true, { expires: 365 });
    initializeAndTrack(location);
  };

  const neccessaryCookies = [
    {
      id: "_GRECAPTCHA",
      duration: t("cookies.dropdown.neccessary.cookies.recaptcha.duration"),
      description: t(
        "cookies.dropdown.neccessary.cookies.recaptcha.description"
      ),
    },
    {
      id: "oxynapse_consent",
      duration: t("cookies.dropdown.neccessary.cookies.consent.duration"),
      description: t("cookies.dropdown.neccessary.cookies.consent.description"),
    },
    {
      id: "oxynapse-consent-neccessary",
      duration: t(
        "cookies.dropdown.neccessary.cookies.neccessary-consent.duration"
      ),
      description: t(
        "cookies.dropdown.neccessary.cookies.neccessary-consent.description"
      ),
    },
    {
      id: "oxynapse-consent-analytics",
      duration: t(
        "cookies.dropdown.neccessary.cookies.analytics-consent.duration"
      ),
      description: t(
        "cookies.dropdown.neccessary.cookies.analytics-consent.description"
      ),
    },
  ];

  const analyticsCookies = [
    {
      id: "_ga",
      duration: t("cookies.dropdown.analytics.cookies.ga.duration"),
      description: t("cookies.dropdown.analytics.cookies.ga.description"),
    },
    {
      id: "_ga_<container-id>",
      duration: t("cookies.dropdown.analytics.cookies.ga_container.duration"),
      description: t(
        "cookies.dropdown.analytics.cookies.ga_container.description"
      ),
    },
  ];

  return (
    <>
      {displayBanner && (
        <div className={styles.CookiesBanner}>
          <h3 className={styles.CookiesBanner__title}>
            {t("cookies.banner.title")}
          </h3>
          <div className={styles.CookiesBanner__contentWrapper}>
            <p className={styles.CookiesBanner__details}>
              {t("cookies.banner.text")}{" "}
              <span
                className={styles.CookiesBanner__details__seeMore}
                onClick={toggleDropdown}
              >
                {t("cookies.banner.details")}
              </span>
            </p>
            <div className={styles.CookiesBanner__btnWrapper}>
              <Button
                text={t("cookies.banner.customize")}
                buttonStyle={styles.CookiesBanner__btn_border}
                onClick={toggleDropdown}
                icon={
                  <FaCaretDown
                    className={`${styles.CookiesBanner__btn__icon} ${
                      dropdownOpen ? styles.CookiesBanner__btn__icon_open : ""
                    }`}
                  ></FaCaretDown>
                }
              ></Button>
              <Button
                text={t("cookies.banner.acceptneccessary")}
                buttonStyle={styles.CookiesBanner__btn_border}
                onClick={handleDecline}
              ></Button>
              <Button
                text={t("cookies.banner.acceptall")}
                buttonStyle={styles.CookiesBanner__btn_fill}
                accentButton
                onClick={handleAccept}
              ></Button>
            </div>
          </div>

          <div
            className={`${styles.CookiesBanner__dropdown} ${
              dropdownOpen ? styles.CookiesBanner__dropdown_visible : ""
            }`}
          >
            <div className={styles.CookiesBanner__dropdown__scrollableWrapper}>
              <h3 className={styles.CookiesBanner__title}>
                {t("cookies.dropdown.title")}
              </h3>
              <p className={styles.CookiesBanner__details}>
                {t("cookies.dropdown.text")}
              </p>
              <CookiesType
                title={t("cookies.dropdown.neccessary.title")}
                text={t("cookies.dropdown.neccessary.text")}
                cookies={neccessaryCookies}
                alwaysActive
              ></CookiesType>

              <CookiesType
                title={t("cookies.dropdown.analytics.title")}
                text={t("cookies.dropdown.analytics.text")}
                cookies={analyticsCookies}
                value={analyticsCookiesActive}
                setValue={setAnalyticsCookiesActive}
              ></CookiesType>
            </div>
            <Button
              text={t("cookies.dropdown.save")}
              buttonStyle={styles.CookiesBanner__btn_border}
              onClick={handleSavePreferences}
            ></Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesBanner;
