// extracted by mini-css-extract-plugin
export var CookiesBanner = "CookiesBanner-module--CookiesBanner--abec3";
export var CookiesBanner__btnWrapper = "CookiesBanner-module--CookiesBanner__btnWrapper--8d532";
export var CookiesBanner__btn__icon = "CookiesBanner-module--CookiesBanner__btn__icon--6840d";
export var CookiesBanner__btn__icon_open = "CookiesBanner-module--CookiesBanner__btn__icon_open--4f3a1";
export var CookiesBanner__btn_border = "CookiesBanner-module--CookiesBanner__btn_border--0d728";
export var CookiesBanner__btn_fill = "CookiesBanner-module--CookiesBanner__btn_fill--e9dd0";
export var CookiesBanner__contentWrapper = "CookiesBanner-module--CookiesBanner__contentWrapper--38d44";
export var CookiesBanner__details = "CookiesBanner-module--CookiesBanner__details--fd192";
export var CookiesBanner__details__seeMore = "CookiesBanner-module--CookiesBanner__details__seeMore--ed227";
export var CookiesBanner__dropdown = "CookiesBanner-module--CookiesBanner__dropdown--cd049";
export var CookiesBanner__dropdown__scrollableWrapper = "CookiesBanner-module--CookiesBanner__dropdown__scrollableWrapper--f3a9b";
export var CookiesBanner__dropdown_visible = "CookiesBanner-module--CookiesBanner__dropdown_visible--c0c64";
export var CookiesBanner__title = "CookiesBanner-module--CookiesBanner__title--94cc4";
export var buttonTextStyle = "CookiesBanner-module--buttonTextStyle--77a61";