import React from "react";
import * as styles from "./Typewriter.module.css";
import Typewriter from "typewriter-effect";
import { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const TypewriterComponent = () => {
  const [showTypewriter, setShowTypewriter] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowTypewriter(true);
  }, []);

  return (
    <div className={styles.Typewriter__title}>
      {t("intro.title.part1regular")} <br />
      {t("intro.title.part2regular")}
      <span className={styles.Typewriter__title_highlighted}>
        {t("intro.title.part1highlight")} <br />
      </span>
      {t("intro.title.part3regular")} <br />
      {showTypewriter && (
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
            delay: 75,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(
                '<span style="background: -webkit-linear-gradient(left ,#3dbbd9, #48def7);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">' +
                  t("intro.title.part2highlight1") +
                  "</span>"
              )
              .pauseFor(700)
              .deleteChars(t("intro.title.part2highlight1").length)
              .typeString(
                '<span style="background: -webkit-linear-gradient(left ,#3dbbd9, #48def7);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">' +
                  t("intro.title.part2highlight2") +
                  "</span>"
              )
              .pauseFor(700)

              .deleteChars(t("intro.title.part2highlight2").length)
              .typeString(
                '<span style="background: -webkit-linear-gradient(left ,#3dbbd9, #48def7);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">' +
                  t("intro.title.part2highlight3") +
                  "</span>"
              )
              .pauseFor(700)
              .deleteChars(t("intro.title.part2highlight3").length)
              .start();
          }}
        />
      )}
    </div>
  );
};

export default TypewriterComponent;
