import React from "react";
import * as styles from "./CookiesList.module.css";
import { useTranslation } from "gatsby-plugin-react-i18next";

const CookiesList = ({ cookies }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <ul>
        {cookies.map((cookie) => (
          <li className={styles.cookiesListItem} key={cookie.id}>
            <div>
              {t("cookies.dropdown.cookie")}: <span>{cookie.id}</span>
            </div>
            <div>
              {t("cookies.dropdown.duration")}: <span>{cookie.duration}</span>
            </div>
            <div>
              {t("cookies.dropdown.description")}:
              <span>{cookie.description}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CookiesList;
