import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { FaTimesCircle } from "@react-icons/all-files/fa/FaTimesCircle";
import React, { useEffect, useState, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import contactFormService from "../../api/service/contact-form-service";
import Button from "../../_shared/componenets/Button/Button";
import InputItem from "../../_shared/componenets/InputItem/InputItem";
import * as styles from "./ContactForm.module.css";
import FormInputLabel from "./FormInputLabel/FormInputLabel";
import BlobSvg from "../../assets/svg/blob.inline.svg";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ContactForm = ({ autofillForm, setAutofillForm }) => {
  const { t } = useTranslation();

  const requirementsOptions = {
    email: {
      required: t("contact.form.email.required"),
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: t("contact.form.email.valid"),
      },
    },
    fullName: {
      required: t("contact.form.fullname.required"),
      maxLength: {
        value: 50,
        message: t("contact.form.fullname.valid"),
      },
    },
    message: {
      required: t("contact.form.message.required"),
      maxLength: {
        value: 1000,
        message: t("contact.form.message.valid"),
      },
    },
  };
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [failedSubmit, setFailedSubmit] = useState(false);
  const [failedSumbitMessage, setFailedSubmitMessage] = useState("");

  const methods = useForm({
    mode: "onSubmit",
    defaultValues: { name: "", email: "", message: "" },
  });

  const { isSubmitting } = methods.formState;

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("homepage");

    methods.setValue("recaptcha", token);
  }, [executeRecaptcha, methods]);

  const onSubmit = (data) => {
    return contactFormService
      .submit(data)
      .then((response) => {
        if (response.error === undefined) {
          setSuccessSubmit(true);
          setFailedSubmit(false);
        } else {
          setFailedSubmitMessage(t("contact.form.errorLabel.server"));
          setFailedSubmit(true);
          setSuccessSubmit(false);
        }
      })
      .catch((error) => {
        setFailedSubmitMessage(t("contact.form.errorLabel.network"));
        setFailedSubmit(true);
        setSuccessSubmit(false);
      });
  };

  useEffect(() => {
    methods.register("recaptcha", { required: true });
  }, [methods]);
  //reset forme na submit, po potrebi izbaciti ako zelimo da zadrzimo neka polja
  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset({ name: "", email: "", message: "", recaptcha: "" });
      handleReCaptchaVerify();
      setAutofillForm("none");
    }
  }, [
    methods.formState,
    methods.submittedData,
    methods.reset,
    handleReCaptchaVerify,
  ]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    if (autofillForm !== "none") {
      methods.setValue(
        "message",
        t("contact.form.message.autofill." + autofillForm)
      );
    }
  }, [autofillForm, methods, t]);

  return (
    <FormProvider {...methods}>
      <div className={styles.ContactForm}>
        <BlobSvg className={styles.ContactForm__backgroundImage}></BlobSvg>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.ContactForm__form}
        >
          <div className={styles.ContactForm__form__group}>
            <FormInputLabel
              id={"name"}
              text={t("contact.form.fullname.label")}
            ></FormInputLabel>
            <InputItem
              id={"name"}
              name={"name"}
              requirements={requirementsOptions.fullName}
            ></InputItem>
          </div>
          <div className={styles.ContactForm__form__group}>
            <FormInputLabel
              id={"email"}
              text={t("contact.form.email.label")}
            ></FormInputLabel>
            <InputItem
              id={"email"}
              name="email"
              //type="email"
              requirements={requirementsOptions.email}
            ></InputItem>
          </div>
          <div className={styles.ContactForm__form__group}>
            <FormInputLabel
              id={"message"}
              text={t("contact.form.message.label")}
            ></FormInputLabel>

            <InputItem
              id={"message"}
              name={"message"}
              requirements={requirementsOptions.message}
              multiline
            ></InputItem>
          </div>
          <small className={styles.ContactForm__recaptchaLegal}>
            <Trans i18nKey="contact.form.recaptcha.legal">
              This site is protected by reCAPTCHA and the Google
              <a
                href="https://policies.google.com/privacy"
                target={"_blank"}
                rel="noreferrer"
              >
                {" "}
                Privacy Policy{" "}
              </a>
              and
              <a
                href="https://policies.google.com/terms"
                target={"_blank"}
                rel="noreferrer"
              >
                {" "}
                Terms of Service{" "}
              </a>
              apply.
            </Trans>
          </small>

          {successSubmit && !isSubmitting && (
            <div className={styles.ContactForm__message_success}>
              <FaCheckCircle className={styles.icon} />
              {t("contact.form.successLabel")}
            </div>
          )}
          {failedSubmit && !isSubmitting && (
            <div className={styles.ContactForm__message_error}>
              <FaTimesCircle className={styles.icon} />
              {failedSumbitMessage}
            </div>
          )}
          <Button
            inputType="submit"
            text={t("contact.form.button")}
            disabled={isSubmitting}
            buttonStyle={styles.ContactForm__button}
            accentButton
          ></Button>
        </form>
      </div>
    </FormProvider>
  );
};

export default ContactForm;
