import React from "react";
import * as styles from "./Career.module.css";
import Button from "../../_shared/componenets/Button/Button";
import PartnershipSvg from "../../assets/svg/partnership.inline.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { scroller } from "react-scroll";

const Career = ({ setAutofillForm }) => {
  const { t } = useTranslation();
  const scrollToContact = () => {
    setAutofillForm("career");
    scroller.scrollTo("contact", {
      duration: 1000,
      delay: 50,
      smooth: true,
    });
  };
  return (
    <div id="careers" className={styles.Career}>
      <PartnershipSvg className={styles.Career_image}></PartnershipSvg>
      <div className={styles.Career__textWrapper}>
        <span className={styles.Career__tag}>{t("career.tag")}</span>

        <span className={styles.Career__title}>{t("career.title")}</span>
        <Button
          text={t("career.button")}
          buttonStyle={styles.Career__btn}
          onClick={scrollToContact}
          icon={<FaArrowRight className={styles.Career__btn__icon} />}
          accentButton
        />
      </div>
    </div>
  );
};

export default Career;
