import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import CassandraLogo from "../../assets/svg/technology-logo/apache_cassandra.inline.svg";
import KafkaLogo from "../../assets/svg/technology-logo/apache_kafka.inline.svg";
import SparkLogo from "../../assets/svg/technology-logo/apache_spark.inline.svg";
import ElasticLogo from "../../assets/svg/technology-logo/elastic.inline.svg";
import GraphqlLogo from "../../assets/svg/technology-logo/graphql.inline.svg";
import InfluxLogo from "../../assets/svg/technology-logo/influxdata.inline.svg";
import JavaLogo from "../../assets/svg/technology-logo/java.inline.svg";
import PostgresqlLogo from "../../assets/svg/technology-logo/postgresql.inline.svg";
import PytorchLogo from "../../assets/svg/technology-logo/pytorch.inline.svg";
import ReactJSLogo from "../../assets/svg/technology-logo/reactjs.inline.svg";
import SpringLogo from "../../assets/svg/technology-logo/springio.inline.svg";
import * as styles from "./Technologies.module.css";

const Technologies = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Technologies}>
      <div className={styles.Technologies__titleWrapper}>
        <h2 className={styles.Technologies__title}>{t("technology.title")}</h2>
        <p className={styles.Technologies__tag}>{t("technology.tag")}</p>
      </div>

      <div className={styles.Technologies__itemWrapper}>
        <h3 className={styles.Technologies__item__title}>
          {t("technology.paragraph1.title")}
        </h3>
        <p className={styles.Technologies__item__text}>
          {t("technology.paragraph1.text")}
        </p>

        <h3 className={styles.Technologies__item__title}>
          {t("technology.paragraph2.title")}
        </h3>
        <p className={styles.Technologies__item__text}>
          {t("technology.paragraph2.text")}
        </p>
      </div>
      <div className={styles.Technologies__logoWrapper}>
        <JavaLogo />
        <ReactJSLogo />
        <PytorchLogo />
        <PostgresqlLogo />
        <SpringLogo />
        <InfluxLogo />
        <GraphqlLogo />
        <ElasticLogo />
        <SparkLogo />
        <KafkaLogo />
        <CassandraLogo />
      </div>
    </div>
  );
};

export default Technologies;
