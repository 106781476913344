import React from "react";
import * as styles from "./Dropdown.module.css";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";

const Dropdown = ({ children, item, addedStyle, open, toggle }) => {
  return (
    <div className={styles.Dropdown}>
      <div className={styles.Dropdown__button} onClick={toggle}>
        {item}

        <FaChevronDown
          className={`${styles.Dropdown__button__icon} ${
            open ? styles.Dropdown__button__icon_open : ""
          }`}
        />
      </div>
      {open && (
        <div className={`${styles.Dropdown__container} ${addedStyle}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
