// extracted by mini-css-extract-plugin
export var Roadmap = "Roadmap-module--Roadmap--9daec";
export var Roadmap__animationTrigger = "Roadmap-module--Roadmap__animationTrigger--50ee9";
export var Roadmap__animationTriggerWrapper = "Roadmap-module--Roadmap__animationTriggerWrapper--33744";
export var Roadmap__animationTriggerWrapper_hidden = "Roadmap-module--Roadmap__animationTriggerWrapper_hidden--f3809";
export var Roadmap__animationTrigger_final = "Roadmap-module--Roadmap__animationTrigger_final--b77e2";
export var Roadmap__background = "Roadmap-module--Roadmap__background--6f536";
export var Roadmap__btn = "Roadmap-module--Roadmap__btn--f3ac4";
export var Roadmap__btn__icon = "Roadmap-module--Roadmap__btn__icon--38a39";
export var Roadmap__buttonWrapper = "Roadmap-module--Roadmap__buttonWrapper--e85aa";
export var Roadmap__buttonWrapper_visible = "Roadmap-module--Roadmap__buttonWrapper_visible--9847d";
export var Roadmap__contentWrapper = "Roadmap-module--Roadmap__contentWrapper--c5ef2";
export var Roadmap__image = "Roadmap-module--Roadmap__image--a78d6";
export var Roadmap__imageWrapper = "Roadmap-module--Roadmap__imageWrapper--91a26";
export var Roadmap__image_visible = "Roadmap-module--Roadmap__image_visible--0923b";
export var Roadmap__stepWrapper = "Roadmap-module--Roadmap__stepWrapper--dae43";
export var Roadmap__stickyWrapper = "Roadmap-module--Roadmap__stickyWrapper--36804";
export var Roadmap__title = "Roadmap-module--Roadmap__title--2b5f8";
export var Roadmap__title_visible = "Roadmap-module--Roadmap__title_visible--e3865";