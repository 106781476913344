import React from "react";
import * as styles from "./AppLayout.module.css";
import CookiesBanner from "../../features/CookiesBanner/CookiesBanner";

const AppLayout = ({ header, footer, children }) => {
  return (
    <div className={styles.Layout}>
      {header}
      <div className={styles.Layout__content}>{children}</div>
      {footer}
      <CookiesBanner></CookiesBanner>
    </div>
  );
};

export default AppLayout;
