import React from "react";
import * as styles from "./MenuLink.module.css";
import { Link } from "react-scroll";
import { useState } from "react";

const MenuLink = ({ href, text, scrollOffset, onClick = () => {} }) => {
  const [active, setActive] = useState(false);
  const setActiveLink = () => {
    setActive(true);
  };
  const setInactiveLink = () => {
    setActive(false);
  };
  return (
    <Link
      //activeClass={styles.activeLink}
      to={href}
      spy={true}
      smooth={true}
      offset={scrollOffset}
      delay={50}
      duration={1000}
      className={`${styles.Menulink} ${active ? styles.Menulink_active : ""}`}
      // containerId="scrollContainer"
      onSetActive={setActiveLink}
      onSetInactive={setInactiveLink}
      onClick={onClick}
      href={href}
    >
      {text}
    </Link>
    // <a onClick={onClick} className={styles.menuLink} href={href}>
    //   {text}
    // </a>
  );
};

export default MenuLink;
