import React from "react";
import * as styles from "./InputItem.module.css";
import { useFormContext } from "react-hook-form";

const InputItem = ({
  id,
  name,
  placeholderText,
  inputItemStyleClass,
  value,
  type = "text",
  requirements,
  multiline,
}) => {
  const { register, formState } = useFormContext();
  return (
    <>
      <div className={styles.InputItem__wrapper}>
        {multiline ? (
          <textarea
            className={`${styles.InputItem__input} ${inputItemStyleClass} ${
              formState.errors[id] ? styles.InputItem__input_error : ""
            }`}
            type={type}
            placeholder={placeholderText}
            id={id}
            name={name}
            value={value}
            {...register(id, requirements)}
          ></textarea>
        ) : (
          <input
            className={`${styles.InputItem__input} ${inputItemStyleClass} ${
              formState.errors[id] ? styles.InputItem__input_error : ""
            }`}
            type={type}
            placeholder={placeholderText}
            id={id}
            name={name}
            value={value}
            {...register(id, requirements)}
          />
        )}
        <label
          className={`${styles.InputItem__message_error} ${
            formState.errors[id] ? styles.InputItem__message_error_visible : ""
          }`}
        >
          {formState.errors[id] ? formState.errors[id].message : ""}
        </label>
      </div>
    </>
  );
};

export default InputItem;
