import React from "react";
import ContactForm from "../ContactForm/ContactForm";
import * as styles from "./Contact.module.css";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FaPhone } from "@react-icons/all-files/fa/FaPhone";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaMapPin } from "@react-icons/all-files/fa/FaMapPin";

const Contact = ({ autofillForm, setAutofillForm }) => {
  const { t } = useTranslation();
  return (
    // <Element name="contact">
    <div id="contact" className={styles.Contact}>
      <div className={styles.Contact__titleWrapper}>
        <p className={styles.Contact__tag}>{t("contact.tag")}</p>
        <h2 className={styles.Contact__title}>{t("contact.title")}</h2>
        <div className={styles.Contact__infoWrapper}>
          <FaEnvelope /> office@oxynapse.com
          <br />
          <FaPhone /> +387 65 76 76 76
          <br />
          <FaMapPin /> {t("contact.address")}
          <br />
        </div>
      </div>
      <ContactForm
        autofillForm={autofillForm}
        setAutofillForm={setAutofillForm}
      />
    </div>
    //</Element>
  );
};

export default Contact;
