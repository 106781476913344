import React from "react";
import * as styles from "./CookiesType.module.css";
import { useState } from "react";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import CookiesList from "./CookiesList/CookiesList";
import ToggleSwitch from "../../../_shared/componenets/ToggleSwitch/ToggleSwitch";
import { useTranslation } from "gatsby-plugin-react-i18next";

const CookiesType = ({
  title,
  text,
  alwaysActive,
  cookies,
  id,
  value = false,
  setValue = () => {},
}) => {
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <div className={styles.container}>
      <div className={styles.cookiesTypeHeader}>
        <FaChevronRight
          onClick={toggleDropdown}
          className={`${styles.icon}  ${
            dropdownOpen ? styles.iconDropdownOpen : ""
          }`}
        ></FaChevronRight>
        <h4 className={styles.title} onClick={toggleDropdown}>
          {title}
        </h4>
        {alwaysActive ? (
          <span>{t("cookies.dropdown.alwaysactive")}</span>
        ) : (
          <>
            <ToggleSwitch onChange={setValue} value={value}></ToggleSwitch>
          </>
        )}
      </div>

      <p className={styles.CookiesType__details}>{text}</p>
      <div
        className={`${styles.dropdown} ${
          dropdownOpen ? styles.dropdownVisible : ""
        }`}
      >
        <CookiesList cookies={cookies}></CookiesList>
      </div>
    </div>
  );
};

export default CookiesType;
