import React from "react";
import * as styles from "./ToggleSwitch.module.css";
const ToggleSwitch = ({ value, onChange }) => {
  return (
    <>
      <label className={styles.Toggle__wrapper}>
        <input
          type="checkbox"
          className={styles.Toggle__input}
          defaultChecked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <span className={styles.Toggle__slider}></span>
      </label>
    </>
  );
};

export default ToggleSwitch;
