import React, { useState } from "react";
import * as styles from "./FAQ.module.css";
import Question from "./FAQItem/Question/Question";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Faq = () => {
  const { t } = useTranslation();

  const questions = [
    {
      id: 1,
      question: t("faq.question1.question"),
      answer: t("faq.question1.answer"),
    },
    {
      id: 2,
      question: t("faq.question2.question"),
      answer: t("faq.question2.answer"),
    },
    {
      id: 3,
      question: t("faq.question3.question"),
      answer: t("faq.question3.answer"),
    },
  ];
  const [currentItem, setCurrentItem] = useState({});
  const handleQuestionClick = (question) => {
    if (currentItem.id === question.id) {
      setCurrentItem({});
    } else {
      setCurrentItem(question);
    }
  };
  return (
    <div className={styles.Faq}>
      <span className={styles.Faq__backgroundText}>{"FAQ"}</span>

      <span className={styles.Faq__tag}>{t("faq.tag")}</span>

      <h2 className={styles.Faq__title}>{t("faq.title")}</h2>
      <div className={styles.Faq_questionsWrapper}>
        {questions.map((question) => (
          <Question
            key={question.id}
            onClick={() => {
              handleQuestionClick(question);
            }}
            question={question}
            isSelected={currentItem !== null && currentItem.id === question.id}
          ></Question>
        ))}
      </div>
    </div>
  );
};

export default Faq;
