import { post } from "../client";
const baseConfig = {
  baseURL: process.env.GATSBY_FORM_API_URL,
};

const submit = async (data) => {
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("message", data.message);
  formData.append("g-recaptcha-response", data.recaptcha);

  const response = await post(baseConfig.baseURL, {
    body: formData,
    headers: {
      Accept: "application/json",
    },
  });
  return response;
};

const contactFormService = { submit };

export default contactFormService;
