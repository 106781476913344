import React from "react";
import * as styles from "./Intro.module.css";
import Button from "../../_shared/componenets/Button/Button";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import TypewriterComponent from "../Typewriter/Typewriter";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { scroller } from "react-scroll";

const Intro = () => {
  const { t } = useTranslation();

  const scrollToRoadmap = () => {
    scroller.scrollTo("roadmap", {
      duration: 500,
      smooth: true,
    });
  };

  return (
    <div className={styles.Intro}>
      <div className={styles.Intro__titleWrapper}>
        <div className={styles.Intro__typewriterWrapper}>
          <TypewriterComponent></TypewriterComponent>
        </div>
        <div className={styles.Intro__btnWrapper}>
          <Button
            text={t("intro.button")}
            buttonStyle={styles.Intro__btn}
            onClick={scrollToRoadmap}
            icon={<FaArrowRight className={styles.Intro__btn__icon} />}
            accentButton
          />
        </div>
      </div>
      <div className={styles.Intro__image}>
        <StaticImage
          alt={"cyberhead"}
          loading={"eager"}
          src="../../images/cyberhead-small.png"
          placeholder={"blurred"}
        ></StaticImage>
      </div>
    </div>
  );
};

export default Intro;
