import { graphql } from "gatsby";
import * as React from "react";
import Career from "../features/Career/Career";
import Contact from "../features/Contact/Contact";
import Faq from "../features/FAQ/FAQ";
import Footer from "../features/Footer/Footer";
import Header from "../features/Header/Header";
import Intro from "../features/Intro/Intro";
import Roadmap from "../features/Roadmap/Roadmap";
import Services from "../features/Services/Services";
import AppLayout from "../layouts/AppLayout/AppLayout";
import Seo from "../_shared/componenets/Seo/Seo";
import "./index.css";
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useInView } from "react-intersection-observer";

const IndexPage = ({ location }) => {
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "en";
    }

    const lang =
      navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";

    switch (lang) {
      case "sr":
        return "sr";
      default:
        return "en";
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      const urlLang = getRedirectLanguage();
      if (urlLang !== "en") {
        navigate(`/${urlLang}/`, { replace: true });
      } else {
        document.documentElement.setAttribute("lang", urlLang);
      }
    } else {
      document.documentElement.setAttribute(
        "lang",
        location.pathname.replaceAll("/", "")
      );
    }
  }, [location.pathname]);

  const collapseTrigger = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (collapseTrigger.inView) setCollapseRoadmap(true);
  }, [collapseTrigger.inView]);

  const [offset, setOffset] = useState();
  const [autofillForm, setAutofillForm] = useState("none");
  const [collapseRoadmap, setCollapseRoadmap] = useState(false);

  return (
    <AppLayout
      header={
        <Header
          roadmapOffset={offset}
          setCollapseRoadmap={setCollapseRoadmap}
        />
      }
      footer={<Footer />}
    >
      <Intro></Intro>
      <Roadmap
        setOffset={setOffset}
        setAutofillForm={setAutofillForm}
        collapseRoadmap={collapseRoadmap}
      ></Roadmap>
      <div ref={collapseTrigger.ref}>
        <Services></Services>
        <Career setAutofillForm={setAutofillForm}></Career>
        <Contact
          autofillForm={autofillForm}
          setAutofillForm={setAutofillForm}
        ></Contact>
        <Faq></Faq>
      </div>
    </AppLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <Seo />;
