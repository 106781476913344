import React, { useState, useEffect, useRef } from "react";
import RoadmapSvg from "../../assets/svg/project-stages.inline.svg";
import BackgroundSvg from "../../assets/svg/roadmap-background.inline.svg";

import Step from "./Step/Step";
import * as styles from "./Roadmap.module.css";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useInView } from "react-intersection-observer";
import { FaPhone } from "@react-icons/all-files/fa/FaPhone";
import Button from "../../_shared/componenets/Button/Button";
import { scroller } from "react-scroll";

const Roadmap = ({ setOffset, setAutofillForm, collapseRoadmap }) => {
  const { t } = useTranslation();

  const imageView = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const trigger1 = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const trigger2 = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const trigger3 = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const trigger4 = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const trigger5 = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const titleView = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const [overflowHidden, setOverflowHidden] = useState(false);

  useEffect(() => {
    if (trigger5.inView || collapseRoadmap) {
      if (!overflowHidden) {
        window.scrollBy(0, -overflowRef.current.clientHeight);
        setOverflowHidden(true);
      }
    }
  }, [trigger5.inView, collapseRoadmap, overflowHidden]);

  const overflowRef = useRef();

  useEffect(() => {
    setOffset(-overflowRef.current.clientHeight);
  }, [overflowRef, overflowHidden, setOffset]);

  const scrollToContact = () => {
    setAutofillForm("call");
    scroller.scrollTo("contact", {
      duration: 1000,
      //delay: 50,
      smooth: true,
      offset: overflowHidden ? 0 : -overflowRef.current.clientHeight,
    });
  };
  return (
    <div className={`${styles.Roadmap}`} id="roadmap">
      <div className={styles.Roadmap__stickyWrapper}>
        <h2
          ref={titleView.ref}
          className={`${styles.Roadmap__title} ${
            titleView.inView ? styles.Roadmap__title_visible : ""
          }`}
        >
          {t("roadmap.title")}
        </h2>
        <div className={styles.Roadmap__contentWrapper}>
          <div className={styles.Roadmap__imageWrapper}>
            <div
              ref={imageView.ref}
              className={`${styles.Roadmap__image} ${
                imageView.inView ? styles.Roadmap__image_visible : ""
              }`}
            >
              <RoadmapSvg />
            </div>
          </div>
          <div className={styles.Roadmap__stepWrapper}>
            <BackgroundSvg
              className={styles.Roadmap__background}
            ></BackgroundSvg>
            <Step
              number={1}
              text={t("roadmap.step1.text")}
              title={t("roadmap.step1.title")}
              visible={trigger1.inView || overflowHidden}
            ></Step>
            <Step
              number={2}
              text={t("roadmap.step2.text")}
              title={t("roadmap.step2.title")}
              visible={trigger2.inView || overflowHidden}
            ></Step>
            <Step
              number={3}
              text={t("roadmap.step3.text")}
              title={t("roadmap.step3.title")}
              visible={trigger3.inView || overflowHidden}
            ></Step>
            <Step
              number={4}
              text={t("roadmap.step4.text")}
              title={t("roadmap.step4.title")}
              visible={trigger4.inView || overflowHidden}
            ></Step>
          </div>
        </div>

        <div
          className={`${styles.Roadmap__buttonWrapper} ${
            trigger4.inView || overflowHidden
              ? styles.Roadmap__buttonWrapper_visible
              : ""
          }`}
        >
          <Button
            text={t("roadmap.button")}
            buttonStyle={`${styles.Roadmap__btn}`}
            onClick={scrollToContact}
            icon={<FaPhone className={styles.Roadmap__btn__icon} />}
            accentButton
          />
        </div>
      </div>

      <div
        className={`${styles.Roadmap__animationTriggerWrapper} ${
          overflowHidden ? styles.Roadmap__animationTriggerWrapper_hidden : ""
        }`}
        ref={overflowRef}
      >
        <div
          ref={trigger1.ref}
          className={styles.Roadmap__animationTrigger}
        ></div>
        <div
          ref={trigger2.ref}
          className={styles.Roadmap__animationTrigger}
        ></div>
        <div
          ref={trigger3.ref}
          className={styles.Roadmap__animationTrigger}
        ></div>
        <div
          ref={trigger4.ref}
          className={styles.Roadmap__animationTrigger}
        ></div>
        <div
          ref={trigger5.ref}
          className={styles.Roadmap__animationTrigger_final}
        ></div>
      </div>
    </div>
  );
};

export default Roadmap;
