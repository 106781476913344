import React from "react";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import * as styles from "./SocialMedia.module.css";

const SocialMedia = () => {
  return (
    <div className={styles.container}>
      <a
        href="https://twitter.com/oxynapse"
        target="_blank"
        rel="noreferrer"
        aria-label="Twitter"
      >
        <FaTwitter className={styles.icon} />
      </a>
      <a
        href="https://www.linkedin.com/company/oxynapse"
        target="_blank"
        rel="noreferrer"
        aria-label="Linkedin"
      >
        <FaLinkedin className={styles.icon} />
      </a>
      <a
        href="https://github.com/oxynapse"
        target="_blank"
        rel="noreferrer"
        aria-label="Github"
      >
        <FaGithub className={styles.icon} />
      </a>
    </div>
  );
};

export default SocialMedia;
