import React from "react";
import * as styles from "./LanguageSwitcher.module.css";
import Dropdown from "../../_shared/componenets/Dropdown/Dropdown";
import DropdownItem from "../../_shared/componenets/DropdownItem/DropdownItem";
import Button from "../../_shared/componenets/Button/Button";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { useRef } from "react";
import { useOutsideClickDetection } from "../../hooks/use-outside-click-detection";

const LanguageSwitcher = ({ open, toggle, close }) => {
  const componentRef = useRef();

  useOutsideClickDetection(componentRef, () => {
    close();
  });

  const { language, languages, originalPath } = useI18next();
  return (
    <div className={styles.LanguageSwitcher} ref={componentRef}>
      <Dropdown
        open={open}
        toggle={toggle}
        addedStyle={styles.LanguageSwitcher__dropdown}
        item={
          <Button
            buttonShape={"rectangle"}
            text={language}
            buttonStyle={styles.LanguageSwitcher__button}
          />
        }
      >
        {languages.map((language) => (
          <DropdownItem text={language} key={language}>
            <Link
              to={originalPath}
              language={language}
              className={styles.LanguageSwitcher__link}
            >
              <p>{language}</p>
            </Link>
          </DropdownItem>
        ))}
      </Dropdown>
    </div>
  );
};

export default LanguageSwitcher;
