// extracted by mini-css-extract-plugin
export var Header = "Header-module--Header--9f830";
export var Header__buttonAction = "Header-module--Header__buttonAction--ad54a";
export var Header__buttonHamburger = "Header-module--Header__buttonHamburger--39555";
export var Header__buttonNavigateTop = "Header-module--Header__buttonNavigateTop--631ff";
export var Header__buttonNavigateTop__text = "Header-module--Header__buttonNavigateTop__text--9bbc1";
export var Header__buttonNavigateTop_visible = "Header-module--Header__buttonNavigateTop_visible--c4d08";
export var Header__dropdownWrapper = "Header-module--Header__dropdownWrapper--0168d";
export var Header__dropdownWrapper_closed = "Header-module--Header__dropdownWrapper_closed--36f4b";
export var Header__dropdownWrapper_hidden = "Header-module--Header__dropdownWrapper_hidden--e7fe0";
export var Header__logoWrapper = "Header-module--Header__logoWrapper--350ad";
export var Header_hidden = "Header-module--Header_hidden--24a18";
export var Header_visible = "Header-module--Header_visible--ba718";
export var slideDown = "Header-module--slideDown--18bed";
export var slideUp = "Header-module--slideUp--348f7";