import React from "react";
import * as styles from "./Services.module.css";
import Icon1 from "../../assets/svg/first.inline.svg";
import Icon2 from "../../assets/svg/second.inline.svg";
import Icon3 from "../../assets/svg/third.inline.svg";
import Icon4 from "../../assets/svg/forth.inline.svg";
import ServiceItem from "./ServiceItem/ServiceItem";
import { useTranslation } from "gatsby-plugin-react-i18next";
import BackgroundLineSvg from "../../assets/svg/backgroundline.inline.svg";
import { useInView } from "react-intersection-observer";
import Technologies from "../Technologies/Technologies";

const Services = () => {
  const { t } = useTranslation();
  const titleView = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const serviceView = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  return (
    <div id="services">
      <div className={styles.Services}>
        <h2
          ref={titleView.ref}
          className={`${styles.Services__title} ${
            titleView.inView ? styles.Service__title_visible : ""
          }`}
        >
          {t("services.title")}
        </h2>
        <BackgroundLineSvg
          className={styles.Services_background}
        ></BackgroundLineSvg>
        <div ref={serviceView.ref} className={styles.Services__content}>
          <ServiceItem
            icon={<Icon1 />}
            title={t("services.service1.title")}
            text={t("services.service1.text")}
            visible={serviceView.inView}
          ></ServiceItem>
          <ServiceItem
            icon={<Icon2 />}
            title={t("services.service2.title")}
            text={t("services.service2.text")}
            visible={serviceView.inView}
          ></ServiceItem>
          <ServiceItem
            icon={<Icon3 />}
            title={t("services.service3.title")}
            text={t("services.service3.text")}
            visible={serviceView.inView}
          ></ServiceItem>
          <ServiceItem
            icon={<Icon4 />}
            title={t("services.service4.title")}
            text={t("services.service4.text")}
            visible={serviceView.inView}
          ></ServiceItem>
        </div>
      </div>
      <Technologies></Technologies>
    </div>
  );
};

export default Services;
