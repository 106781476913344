import React from "react";
import * as styles from "./Step.module.css";

const Step = ({ number, title, text, visible }) => {
  return (
    <div className={`${styles.Step} ${visible ? styles.Step_visible : ""}`}>
      <h3 className={styles.Step__title}>
        {number}
        {". "}
        {title}
      </h3>
      <p className={styles.Step__text}>{text}</p>
    </div>
  );
};

export default Step;
