// extracted by mini-css-extract-plugin
export var ContactForm = "ContactForm-module--ContactForm--cb61a";
export var ContactForm__backgroundImage = "ContactForm-module--ContactForm__backgroundImage--a44e1";
export var ContactForm__button = "ContactForm-module--ContactForm__button--64968";
export var ContactForm__form = "ContactForm-module--ContactForm__form--88f85";
export var ContactForm__form__group = "ContactForm-module--ContactForm__form__group--5285e";
export var ContactForm__message_error = "ContactForm-module--ContactForm__message_error--00cda";
export var ContactForm__message_success = "ContactForm-module--ContactForm__message_success--c6928";
export var ContactForm__recaptcha = "ContactForm-module--ContactForm__recaptcha--17181";
export var ContactForm__recaptchaLegal = "ContactForm-module--ContactForm__recaptchaLegal--b78eb";
export var icon = "ContactForm-module--icon--c714f";