import React from "react";
import * as styles from "./Button.module.css";

const Button = ({
  text,
  buttonStyle,
  textStyle,
  onClick = () => {},
  inputType = "button",
  onSubmit = () => {},
  disabled = false,
  accentButton,
  icon,
  ariaLabel,
}) => {
  return (
    <button
      type={inputType}
      className={` ${styles.Button_default} ${buttonStyle} ${
        accentButton ? styles.Button_accent : ""
      }`}
      onClick={onClick}
      onSubmit={onSubmit}
      disabled={disabled}
      {...(ariaLabel && { "aria-label": ariaLabel })}
    >
      <div className={`${textStyle} ${styles.text}`}>
        {text && <span>{text}</span>}
        {icon}
      </div>
    </button>
  );
};

export default Button;
