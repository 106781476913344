import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import React from "react";
import * as styles from "./Question.module.css";

const Question = ({ question, isSelected, onClick }) => {
  return (
    <div className={`${styles.Question} `}>
      <div onClick={onClick} className={styles.Question__textWrapper}>
        <span className={styles.Question__text}>{question.question}</span>
        <FaChevronRight
          className={`${styles.Question__icon}  ${
            isSelected ? styles.Question__icon_selected : ""
          }`}
        ></FaChevronRight>
      </div>

      <div
        className={`${styles.Question__answer}  ${
          isSelected ? styles.Question__answer_visible : ""
        }`}
      >
        <p> {question.answer}</p>
      </div>
    </div>
  );
};

export default Question;
