import React from "react";
import * as styles from "./FormInputLabel.module.css";

const FormInputLabel = ({ text, id }) => {
  return (
    <label htmlFor={id} className={styles.FormInputLabel}>
      {text}
    </label>
  );
};

export default FormInputLabel;
