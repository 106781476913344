import React from "react";
import * as styles from "./Footer.module.css";
import OxynapseLogo from "../../assets/svg/oxynapselogowhite.inline.svg";
import SocialMedia from "../SocialMedia/SocialMedia";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Footer}>
      <div className={styles.Footer__logoWrapper}>
        <OxynapseLogo className={styles.Footer__logo} />ⓒ{" "}
        {new Date().getFullYear()} oxynapse. {t("footer.rights")}
      </div>
      <div className={styles.Footer__infoWrapper}>
        oxynapse d.o.o.
        <br />
        office@oxynapse.com
        <br />
        +387 65 76 76 76
        <br />
        {t("footer.address")}
        <br />
        JIB: 4404633990008
        <SocialMedia></SocialMedia>
      </div>
    </div>
  );
};

export default Footer;
