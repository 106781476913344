import React from "react";
import * as styles from "./ServiceItem.module.css";

const ServiceItem = ({ icon, title, text, visible }) => {
  return (
    <div
      className={`${styles.Service__card} ${
        visible ? styles.Service__card_visible : ""
      }`}
    >
      <div className={styles.Service__card__image}>{icon}</div>

      <p className={styles.Service__card__title}>{title}</p>
      <p className={styles.Service__card__text}>{text}</p>
    </div>
  );
};

export default ServiceItem;
