import React from "react";
import Button from "../../_shared/componenets/Button/Button";
import Logo from "../Logo/Logo";
import * as styles from "./Header.module.css";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { FaBars } from "@react-icons/all-files/fa/FaBars";
import { FaRegTimesCircle } from "@react-icons/all-files/fa/FaRegTimesCircle";
import { useState } from "react";
import { useEffect } from "react";
import MenuLink from "./MenuLink/MenuLink";
import { FaCaretUp } from "@react-icons/all-files/fa/FaCaretUp";
import { animateScroll, scroller } from "react-scroll";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Header = ({ roadmapOffset, setCollapseRoadmap }) => {
  const { t } = useTranslation();
  const [scrollDirection, setScrollDirection] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuToggled, setMenuToggled] = useState(false);
  const [backToTopButtonVisible, setBackToTopButtonVisible] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const toggleLanguageDropdown = () =>
    setLanguageDropdownOpen(!languageDropdownOpen);
  const toggleMenuOpen = (value) => {
    setMenuOpen(value);
    if (!menuToggled) setMenuToggled(true);
    if (value === false) setLanguageDropdownOpen(false);
  };

  const changeDirection = (direction) => {
    setScrollDirection(direction);
    if (direction === "down") {
      setMenuOpen(false);
      setLanguageDropdownOpen(false);
    }
  };

  const backToTop = () => {
    animateScroll.scrollToTop();
    setScrollDirection("up");
  };

  const scrollToContact = () => {
    scroller.scrollTo("contact", {
      duration: 1000,
      delay: 50,
      smooth: true,
      isDynamic: true,
      offset: roadmapOffset,
    });
    setCollapseRoadmap(true);
  };

  useEffect(() => {
    function handleScroll(e) {
      const scrollY = window.scrollY;

      const direction = scrollY > lastScrollY ? "down" : "up";
      if (scrollY > 300) setBackToTopButtonVisible(true);
      else {
        setBackToTopButtonVisible(false);
      }
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        changeDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    }
    let lastScrollY = window.scrollY;
    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(function setupListener() {
    const mql = window.matchMedia("(min-width: 992px)");
    function handleResize(e) {
      if (e.matches) {
        if (menuOpen) {
          setMenuOpen(false);
        }
        setMenuToggled(false);
      }
    }

    mql.addEventListener("change", handleResize);

    return function cleanupListener() {
      mql.removeEventListener("change", handleResize);
    };
  });

  return (
    <>
      <div
        className={`${styles.Header} ${
          scrollDirection === "down"
            ? styles.Header_hidden
            : styles.Header_visible
        }
    }`}
      >
        <div className={styles.Header__logoWrapper}>
          <Logo onClick={backToTop} />
          {menuOpen ? (
            <FaRegTimesCircle
              className={styles.Header__buttonHamburger}
              onClick={() => {
                toggleMenuOpen(false);
              }}
            />
          ) : (
            <FaBars
              className={styles.Header__buttonHamburger}
              onClick={() => {
                toggleMenuOpen(true);
              }}
            />
          )}
        </div>

        <div
          className={`${styles.Header__dropdownWrapper} ${
            !menuOpen
              ? menuToggled
                ? styles.Header__dropdownWrapper_closed
                : styles.Header__dropdownWrapper_hidden
              : ""
          }`}
        >
          <MenuLink
            href="roadmap"
            text={t("header.roadmap")}
            onClick={() => {
              toggleMenuOpen(false);
            }}
          ></MenuLink>
          <MenuLink
            href="services"
            text={t("header.services")}
            onClick={() => {
              setCollapseRoadmap(true);
            }}
            scrollOffset={roadmapOffset}
          ></MenuLink>
          <MenuLink
            href="careers"
            text={t("header.career")}
            onClick={() => {
              setCollapseRoadmap(true);
            }}
            scrollOffset={roadmapOffset}
          ></MenuLink>
          <LanguageSwitcher
            open={languageDropdownOpen}
            toggle={toggleLanguageDropdown}
            close={() => {
              setLanguageDropdownOpen(false);
            }}
          ></LanguageSwitcher>
          <Button
            buttonShape={"round"}
            text={t("header.contactbutton")}
            buttonStyle={styles.Header__buttonAction}
            onClick={scrollToContact}
            accentButton
          />
        </div>
      </div>
      <div>
        <Button
          buttonShape={"round"}
          buttonStyle={`${styles.Header__buttonNavigateTop} ${
            backToTopButtonVisible
              ? styles.Header__buttonNavigateTop_visible
              : ""
          }`}
          ariaLabel={"back-to-top"}
          onClick={backToTop}
          icon={<FaCaretUp></FaCaretUp>}
          textStyle={styles.Header__buttonNavigateTop__text}
          disabled={!backToTopButtonVisible}
        />
      </div>
    </>
  );
};

export default Header;
